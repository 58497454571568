import React from "react";
import './IconWithShortText.css'

const twocolumniconcard = ({data}) => {
  return (
    
    <div className='imageWithShortTextComponent'>
    <img className='imageWithShortTextComponentImage' src={data?.image?.src} alt='ImageWithText'/>
    <p className='imageWithShortTextComponentText'>{data?.description?.value}</p>
    </div>

  )
}

export default twocolumniconcard