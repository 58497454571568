import React from 'react';


const FooterLinks = (props) =>{
return (<>
  <ul className="bottomFooterLinks">
    {props?.fields?.data?.datasource?.navItems?.map((link, idx) => {
    return link?.isActive?.boolValue ? ( 
     <>
    <li>
    <a href={link?.link?.url} className="nav-bold" target={link?.link?.target}>
     {link?.title?.value}
    </a>
  </li>
</>

  ):(<></>);
})}
  </ul>
 <p className="nav-bold">{props?.fields?.data?.datasource?.title?.value}</p>
 </>
);

}
export default FooterLinks;
