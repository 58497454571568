import React from "react";
import './IconWithLongText.css'

const IconWithLongText = ({data}) => {
  return (
   
    <div className='imageWithLongTextComponent'>
        <img className='imageWithLongTextComponentImage' src={data?.image?.src} alt='ImageWithText'/>
        <p className='imageWithLongTextComponentText'>{data?.description?.value}</p>
    </div>
    
  )
}

export default IconWithLongText