import React,{useEffect} from 'react';
import  './HomeBanner.css';
import jQuery from 'jquery';
//import  '../../../assets/video'
const HomeBanner = (props) => {
  function DocumentLoad(){
    jQuery(document).ready(function () {
      jQuery(".navbar").addClass("bgTransparent");
    });
  }
  function MenuTransparent() {
    if (typeof window !== "undefined") {
      jQuery(window).on("scroll", function () {
        if (jQuery(this).scrollTop() > 200) {
          jQuery(".bgTransparent").addClass("off");
        } else {
          jQuery(".bgTransparent").removeClass("off");
        }
      });
    }
  }
  useEffect(() => {
    MenuTransparent();
    DocumentLoad();
  }, []);
  return (
    
    <section className="homeBannerComponent homeBanner">
      {props?.fields?.Video?.value?.src!=""&&props?.fields?.Video?.value?.src!=null?<>
      <video
      id="myvidMob"
        width="100%"
        height="100%"
        autoPlay
        loop={true}
        muted={true}
        playsInline
        style={{ objectFit: "cover" }}
      >
        <source src={props?.fields?.Video?.value?.src.replace("ashx", props?.fields?.Video?.value?.extension)} type={props?.fields?.Video?.value?.mimeType} />
        Your browser does not support the video tag.
      </video>
      
      
      
      
      </>:
      <>
       <img src={props?.fields?.Image?.value?.src} className="dt-banner" alt="header-banner" />
       </>
       
      }
      <div className="textContainer">
        <h1>{props?.fields?.Title?.value}</h1>
      </div>
    </section>
  );
}

export default HomeBanner;
