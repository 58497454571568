import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
const FooterSocialNavigation = (props) => {
  const { sitecoreContext } = props;
  let themeValue =
  sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value;
if (themeValue == null || themeValue == "") {
  themeValue = sitecoreContext?.SiteTheme?.themeName;
  if (themeValue == null) {
    themeValue = "";
  }
}

return(
    <div className="additionalLinks">
     
     <ul className="socialMediaLinks">
      {props?.fields?.data?.datasource?.navItems?.map((link, idx) => {
          return link?.isActive?.boolValue ? (
            <>
              <li>
                <a href={link?.link?.url} target={link?.link?.target}>
                <img src={link?.image?.src} alt={link?.image?.alt}/>
                </a>
              </li>
            </>

          ) : <></>;
        })}
     </ul>
    </div>
  );
}
export default withSitecoreContext() (FooterSocialNavigation);
