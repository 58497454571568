import React from 'react';


const FooterAwardImages = (props) => {
  return (
    <div className="awardsContainer">
     
      {props?.fields?.items.map((awardImage, idx) => {
     return awardImage?.fields?.IsActive?.value?
       
        <div className="footerawardsSec">
        <p className="footerawardsSecText">{awardImage?.fields?.Title?.value}</p>
        <img className="footerawardsSecImage" src={awardImage?.fields?.Image?.value?.src} alt={awardImage?.fields?.Image?.value?.alt} />
        </div>:<></>
        
      })}
 
    </div>
  );
}
export default FooterAwardImages;
