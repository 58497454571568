import React from 'react';
import  './OurExpertise.css';

const OurExpertise = (props) => { 
  
  return (
    <section className="ourExpertise">
    <div className="textContainer">
        <h3 className="heading">{props?.fields?.data?.datasource?.title?.value}</h3>
        <p className={`description ourExpertiseSubHeading x-large`}>
        {props?.fields?.data?.datasource?.shortDescription?.value}
        </p>
      </div>
    <ul>
      {props?.fields?.data?.datasource?.CardItems?.map((expertise, idx) => {
        return (
          <li key={expertise?.title?.value}>
            <a href={expertise?.link?.url==""||expertise?.link?.url==null?"javascript:void(0)":expertise?.link?.url} target={expertise?.link?.target} className={expertise?.link?.url==""||expertise?.link?.url==null?"hasNoLink":""} >
            <img src={expertise?.image?.src} alt={expertise?.title?.value-`${idx}`} />
            <h6>{expertise?.title?.value}</h6></a>
          </li>
        );
      })}
    </ul>
  </section>

);
}

export default OurExpertise;
