import React, { useState } from "react";
import "./Accordion.css";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import LeaderCard from './leadercard';
import OneCard from './onecard';
import TwoColumnCard from './twocolumncard';
import TwoColumnIconCard from './twocolumniconcard';
const Accordion = (props) => {
  const { sitecoreContext } = props;
  let themeValue =
    sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value;
  if (themeValue == null || themeValue == "") {
    themeValue = sitecoreContext?.SiteTheme?.themeName;
    if (themeValue == null) {
      themeValue = "";
    }
  }
  const [activeIndex, setActiveIndex] = useState(0);
  
    const handleItemClick = (index) => {
     setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
  return (
    <div className="container pt-2 mb-3 mb-lg-5 position-relative slim-body">
          <div className="row">
            <div className="col-12">
          <div className="accordianSectionWrapper">
          <div className="accordion accordion-flush" id="faq-accordion">
          {props?.fields?.data?.datasource?.AccordionChildren?.map((item, idx) => {
            return<>
  {item?.template?.id=="E8B1F43546534E13808979341D90FB97"&& item?.isActive?.boolValue &&(

<div className={`accordion_item ${activeIndex ===idx ? 'active' : ''}`}>

<button className={`accordion_header ${activeIndex ===idx ? 'active' : ''}`} type="button" onClick={() => handleItemClick(idx)}>
  <span className="accordion_header_text">{item?.title?.value}</span>
  <span className="accordion_header_icon">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#052c65"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>
  </span>
</button>

<div className={`accordion_body_wrapper ${activeIndex ===idx ? 'show' : ''}`}>
<div className="accordion_body">
<div dangerouslySetInnerHTML={{ __html: item?.description?.value }}  />
</div>
</div>
</div>
               )}
               {item?.template?.id=="BE574FE58AE0458AB05A9ACD0C995AD7"&& item?.isActive?.boolValue &&(

<div className={`accordion_item ${activeIndex ===idx ? 'active' : ''}`}>

   <button className={`accordion_header ${activeIndex ===idx ? 'active' : ''}`} type="button" onClick={() => handleItemClick(idx)}>
     <span className="accordion_header_text">{item?.title?.value}</span>
     <span className="accordion_header_icon">
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#052c65"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>
     </span>
   </button>
 
 <div className={`accordion_body_wrapper ${activeIndex ===idx ? 'show' : ''}`}>
   <div className="accordion_body">
   <p className="accodianBodyParagraph"> {item?.description?.value}
   </p>
   
       {item?.cardType?.value=="LeaderCard"&&(
         <div className="leadershipCardAccordianWrapper">{
        item?.imageBlock.map((cardItem)=>
        cardItem?.isActive?.boolValue?(
       <LeaderCard data={cardItem}/>):"" 
        
        )
        } </div>
       )}
       {item?.cardType?.value=="OneCard"&&(
         <div className="imageWithListComponentSection">{
         item?.imageBlock.map((cardItem)=>
         cardItem?.isActive?.boolValue?(
         
          <OneCard data={cardItem}/>):"" 
        
         )
        } </div>
       )}
       {item?.cardType?.value=="TwoColumnCard"&&(
        
        <div className="iconWithLongTextSection">
         {
         item?.imageBlock.map((cardItem)=>
         cardItem?.isActive?.boolValue?(
          
          <TwoColumnCard data={cardItem}/>
          ):"" 
         )
         }
         </div>
       )}
       {item?.cardType?.value=="TwoColumnIconCard"&&(
         <div className="iconWithShortTextSection">{
         item?.imageBlock.map((cardItem)=>
         cardItem?.isActive?.boolValue?(
         
          <TwoColumnIconCard data={cardItem}/> ):"" 
         )
       }</div>
       )}
   </div>
 </div>
</div>
               )}

                
</>

          })}


          </div>
          </div>
            </div>
          </div>
          </div>
  );
};

export default withSitecoreContext()(Accordion);
