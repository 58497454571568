import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const FooterNavigation = (props) => {
 return (
 
    <ul className="topFooterLinks">
    {props?.fields?.items.map((link, idx) => {
    return link.fields?.IsActive?.value ? ( 
     <>
    <li><a className="nav-bold" href={link.fields?.Link?.value?.href} target={link.fields?.Link?.value?.target}>{link.fields?.Title?.value}</a>    </li>
</>

  ):(<></>);
})}
            
     </ul>
    
);
}
export default FooterNavigation;
