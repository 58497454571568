import React, { useState, useEffect } from 'react';
import Chevron from "../../../Global/Chevron";
import Cookies from 'js-cookie';
import * as Dictionary from 'i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Breadcrum = (props) => {
  const { sitecoreContext } = props;
  const [langCookie, setlangCookie] = useState("");

  useEffect(() => {
    setlangCookie(Cookies.get('adac_adafz#lang'));
  }, []);
  let translationObj = Dictionary.default.store.data[langCookie]?.translation;

  let bItem = props?.fields?.data?.containerItem;
  let arrayItem = [
    {
      id: bItem["parent"]["parent"]["parent"]["parent"]["id"],
      name: bItem["parent"]["parent"]["parent"]["parent"]["name"],
      url: bItem["parent"]["parent"]["parent"]["parent"]["url"],
      title: bItem["parent"]["parent"]["parent"]["parent"]["field"],
      templateId: bItem["parent"]["parent"]["parent"]["parent"]["template"]["id"],
      templatename: bItem["parent"]["parent"]["parent"]["parent"]["template"]["name"]
    },
    {
      id: bItem["parent"]["parent"]["parent"]["id"],
      name: bItem["parent"]["parent"]["parent"]["name"],
      url: bItem["parent"]["parent"]["parent"]["url"],
      title: bItem["parent"]["parent"]["parent"]["field"],
      templateId: bItem["parent"]["parent"]["parent"]["template"]["id"],
      templatename: bItem["parent"]["parent"]["parent"]["template"]["name"]
    },
    {
      id: bItem["parent"]["parent"]["id"],
      name: bItem["parent"]["parent"]["name"],
      url: bItem["parent"]["parent"]["url"],
      title: bItem["parent"]["parent"]["field"],
      templateId: bItem["parent"]["parent"]["template"]["id"],
      templatename: bItem["parent"]["parent"]["template"]["name"]
    },
    {
      id: bItem["parent"]["id"],
      name: bItem["parent"]["name"],
      url: bItem["parent"]["url"],
      title: bItem["parent"]["field"],
      templateId: bItem["parent"]["template"]["id"],
      templatename: bItem["parent"]["template"]["name"]
    }
  ]
  return (
    <section className="bgColorPrimary headerMargin">
      <div className="breadCrumbContainer pt-4 centered-body-wide">
        <div className="colorSecondary">
         
          {arrayItem?.map((bItem, idx) => {
            {
              return bItem?.id != "11111111111111111111111111111111" && bItem?.id != "0DE95AE441AB4D019EB067441B7C2450"
                && bItem?.id != "8B9486EDFB0055D9A96C9214BA60E424"
                && bItem?.templateId != "ADB6CA4F03EF4F47B9AC9CE2BA53FF97" && bItem.templateId != "33D24E9EE4795ED18F9FE0CFF0D5A3D3"
                && bItem?.id != "55641DF9D6AE51ABA405C63E6CC0C2FD" && bItem.templateId !="48CE0694E8FE41479E79E7B5F21E43E3"
                && bItem?.id !="E89CF2BBFE274B1BA72BD00E018549D8" && bItem.templateId !="5E1F39F9244B4081AB30D4E7480D9299"
                && bItem?.id !="57EC5F51B7754F16804D657043A5E4BF" ? (<>
                   <a href={bItem?.url} className="colorSecondary">{bItem?.title!=null &&bItem?.title?.value!=""? bItem?.title?.value:bItem?.name }</a> 
                  <Chevron />
                  
                </>) : (<></>)
            }

          })} 
           
          {sitecoreContext?.route?.templateId !="5e1f39f9-244b-4081-ab30-d4e7480d9299"
          && sitecoreContext?.route?.templateId !="9a4ced63-fa38-4396-9a4d-6c4a72bf9ff2"? ( sitecoreContext?.route?.fields?.Title!=null
          ?sitecoreContext?.route?.fields?.Title?.value:sitecoreContext?.route?.name) :""}

        </div>
      </div>
    </section>
  );
}
export default withSitecoreContext()(Breadcrum);
