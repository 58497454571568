import React, {useState,useEffect} from 'react';
import LogoWeb from "../../../Global/Logo/LogoWeb";
import LogoMobile from "../../../Global/Logo/LogoMobile";
import Cookies from 'js-cookie';
import * as Dictionary from 'i18next';
const HeaderNavigation = (props) => {
  const [langCookie, setlangCookie] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
	  setlangCookie(Cookies.get('adac_adafz#lang'));
	}, []);
  let translationObj=Dictionary.default.store.data[langCookie]?.translation;
  return (
    <>
      <a className="navbar-brand" href={"/"+langCookie+"/"}>
        <span className="d-none d-lg-inline-block">
          <LogoWeb></LogoWeb>
        </span>
        <span className="d-lg-none">
          <LogoMobile></LogoMobile>
        </span>
      </a>
      <button className="navbar-toggler" type="button">
        <span className="navbar-toggler-icon" id="navbarToggler"></span>
      </button>
      <div className="headerNav justify-content-end collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav">
          {props.fields.data.datasource.navItems.map((link, index) =>
            link?.isActive.boolValue && link?.NavigationItem?.length==0 ? (
              <>
                <li className="nav-item">
                  <a
                    class="nav-link"
                    href={link?.link?.url}
                    title=""
                    target={link?.link?.target}
                  >
                    {link?.title?.value}
                  </a>
                </li>
                {props?.fields?.data?.datasource?.navItems.length-2==index&&(
                <li className="nav-item ">
              <span className="nav-item-seperator"></span>
            </li>)}
              </>
            ) : (
              <>
              
              {link?.NavigationItem?.length > 0 && (
                  <>
                    <li className="nav-item dropdown" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                    <a className="nav-link dropdown-toggle" >
                      {link?.title?.value}</a>
                      {isOpen && (
                      <div className="dropdown-menu">
                       
                        {link.NavigationItem.map((child, index) =>
                          child?.isActive?.boolValue ? (
                            <>
                              <a href={child?.link?.url ? child?.link?.url : "#"}
                                  title="" className="dropdown-item"  target={child?.link?.target}>
                                {child?.title?.value}
                                </a>                              
                            </>
                          ) : (
                            <></>
                          )
                        )}
                      </div>
                      )}
                    </li>
                  </>
                )                
                }
              </>
            )
          )}
        </ul>
      </div>
    </>
  );
}

export default HeaderNavigation;
