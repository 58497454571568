import React from "react";
import styles from "./SecondaryBtn.module.css";

const SecondaryBtn = ({ctaLink,ctaText,ctaTarget}) => {
  return (
    <a className={[styles.secondaryBtn +" btn-default"]} href={ctaLink}
    target={ctaTarget} >
      {ctaText}
    </a>
  );
};

export default SecondaryBtn;