import React from "react";
const Chevron = () => {
    return(
        <>
       <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
       <path d="m 6.33511,16.4831 c -0.7809537,-0.780954 0,-1.5721 0,-1.5721 L 11.2412,10.1421 6.33511,5.37321 c 0,0 -0.8089218,-0.7631182 0,-1.57204 0.8101397,-0.8101397 1.61678,0 1.61678,0 L 13.6649,9.3561 c 0,0 0.3351,0.3347347 0.3351,0.786 0,0.509855 -0.3351,0.786 -0.3351,0.786 l -5.71301,5.555 c 0,0 -0.8999144,0.716866 -1.61678,0 z" fill="white"/>
        </svg>
        </>
    );
}  
export default Chevron;