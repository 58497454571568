import React from "react";
import './ImageWithList.css'
const ImageWithList = ({data}) => {
  
  return (
   
    <div className="imageWithListComponent" >
        <div className="imageWithListComponent_img_wpr">
            <img src={data?.image?.src!=null?data?.image?.src:""} alt='componnentImage'/>
        </div>
        <div className='imageWithListComponent_list_wpr'>
            <h5 className='imageWithListComponent_title'>{data?.title?.value}</h5>
            <div dangerouslySetInnerHTML={{ __html: data?.description?.value }}  />
            {/* <ul className='imageWithListComponent_list_wpr'>
                {data.items.map((item,index)=>{return <li key={index} className='imageWithListComponent_list'><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 9.3V13.5C15 14.325 14.325 15 13.5 15H9.3C9.135 15 9 14.865 9 14.7V10.5C9 9.675 9.675 9 10.5 9H14.7C14.865 9 15 9.135 15 9.3Z" fill="currentColor" fillOpacity="0.96"/>
                </svg></span>
                {item}
                </li>})
                }
            </ul> */}
        </div>
    </div>
    
  )
}

export default ImageWithList