import React from 'react';
import styles from './PartnerWithUs.module.css'
import stylesButtonGroup from '../../../ButtonGroup/ButtonGroup.module.css';

const PartnerWithUs = (props) => {
  return (
    
    <section className={[styles.partnerWithUs]}>
      <div className={[styles.innerContainer]}>
        <div className={[styles.textContainer]}>
          <h3>{props?.fields?.data?.datasource?.title?.value}</h3>
          <p className="medium-reg">
          {props?.fields?.data?.datasource?.subTitle?.value}
          </p>
        </div>
        <div className={[styles.imageWrapper]}>
          {props?.fields?.data?.datasource?.CardItems?.map((cardItems, idx) => {
            return (
              cardItems?.isActive?.boolValue ? 
            <a href ={cardItems?.link.url}>
              <div className={[styles.imgDiv]}>
                <img src={cardItems?.image?.src} alt={`img-${idx}`} />
                <div className={[styles.imgText]}>
                  <h2>{cardItems?.title?.value}</h2>
                  <p>{cardItems?.shortDescription?.value}</p>
                </div>
              </div>
              </a>
            :<></>
            );
          })}
        </div>
        
      </div>
    </section>
  );
        }

export default PartnerWithUs;
