import React, {useState, useEffect} from 'react';
import { Form,createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import { sitecoreApiHost, sitecoreApiKey } from '../../../temp/config';
import { withSitecoreContext  } from '@sitecore-jss/sitecore-jss-react';
import "./ContactUsForm.css";
import ReCAPTCHA from "react-google-recaptcha";
const myCustomFormFactory = createDefaultFieldFactory();

myCustomFormFactory.setComponent(
  "{A81C2FDF-A095-4A78-8E65-6BB5882DD75C}",
  (props) => {
    const [isError, setIsError] = useState(props.errors.length > 0)
    const captchaRef = React.useRef()
    useEffect(() => {
      setCaptchaValue()
    }, [])
    useEffect(() => {
      setIsError(props.errors.length > 0)
    }, [props.errors])


    function setCaptchaValue() {
      props["field"]["model"]["value"] = captchaRef.current.getValue()
    }


    function getCaptchaValue(value) {
      
      if (value) {
        props["field"]["model"]["value"] = value;
        setIsError(false)
      }

    }

    return (
      <>
        {/* <script src='//www.google.com/recaptcha/api.js?hl=en'></script> */}
        
         <ReCAPTCHA sitekey="6LcNo6MqAAAAAFQKbeSh_FYPyAIGUbeciXMocURk" data-callback="captchaCallback" onChange={getCaptchaValue} ref={captchaRef} />
         {isError && <div className="invalid" ><p>{props?.errors[0]}</p></div>}
      </>
    );
  }

);
const ContactUsForm = (props) => 
{
   
   const { sitecoreContext } = props;
    let themeValue =
    sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value;
  if (themeValue == null || themeValue == "") {
    themeValue = sitecoreContext?.SiteTheme?.themeName;
    if (themeValue == null) {
      themeValue = "";
    }
  }
//  console.log("SitecoreContext",withSitecoreContext?.route?.fields);
return<>

  <div className="bgColorPrimary">
        <div className="container">
          <div className="row">
            <div className="col-12">
             
            </div>
          </div>
        </div>
      </div>
  <div className="bgColor pb-5">
  <div class="bgCurve-top">
          <div className="curvePad bgDiv d-sm-none"></div>
          <img src={"/-/media/adac_corporate/data/media/img/Themes/"+themeValue+"/banners/top-banner-curve.ashx"} />
        </div>
        
        <div className="centered-body-sm">
          <div className='contactUsForm'>
        <Form form={props?.fields}
          sitecoreApiHost={sitecoreApiHost}
          sitecoreApiKey={sitecoreApiKey}
          fieldFactory={myCustomFormFactory}
        >
        </Form>
</div>
</div>
      </div>
      </>

}
export default withSitecoreContext () (ContactUsForm);
