import React from "react";
import styles from "./PrimaryBtn.module.css";

const PrimaryBtn = ({ctaLink,ctaText,ctaTarget,showIcon = false, fullWidthInMobile = false}) => {
  return (
    <a className={[styles.primaryBtn +" btn-default "+ [
      fullWidthInMobile ? styles.fullWidthBtn : ""
    ]] } href={ctaLink} target={ctaTarget}>
     {ctaText}
     {showIcon && (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.chevronRight}
        >
          <path
            d="M6.70595 16.7242C6.56724 16.6683 6.44123 16.5864 6.33511 16.4831C6.22887 16.3799 6.1446 16.2574 6.0871 16.1225C6.0296 15.9876 6 15.8431 6 15.6971C6 15.5511 6.0296 15.4065 6.0871 15.2716C6.1446 15.1367 6.22887 15.0142 6.33511 14.911L11.2412 10.1421L6.33511 5.37321C6.12071 5.16475 6.00026 4.88201 6.00026 4.58719C6.00026 4.29237 6.12071 4.00963 6.33511 3.80117C6.54951 3.5927 6.84029 3.47559 7.1435 3.47559C7.4467 3.47559 7.73749 3.5927 7.95189 3.80117L13.6649 9.3561C13.7711 9.45928 13.8554 9.58181 13.9129 9.71668C13.9704 9.85155 14 9.99612 14 10.1421C14 10.2881 13.9704 10.4327 13.9129 10.5676C13.8554 10.7024 13.7711 10.825 13.6649 10.9281L7.95189 16.4831C7.84577 16.5864 7.71976 16.6683 7.58105 16.7242C7.44234 16.7801 7.29365 16.8089 7.1435 16.8089C6.99334 16.8089 6.84466 16.7801 6.70595 16.7242Z"
            fill="white"
          ></path>
        </svg>
      )}</a>
  );
};

export default PrimaryBtn;
