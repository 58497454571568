import React from 'react';
import './TextBanner.css'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const TextBanner = (props) => {
  const { sitecoreContext } = props;
  let themeValue =
    sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value;
  if (themeValue == null || themeValue == "") {
    themeValue = sitecoreContext?.SiteTheme?.themeName;
    if (themeValue == null) {
      themeValue = "";
    }
  };

return(
  <>
  <div class="ImageWithCurveWrp bgColorPrimary">
        <div className={props?.fields?.CssVariant?.fields?.CssClass?.value=="center-aligned"?"container slim-body":"container"}>
          <div className={props?.fields?.CssVariant?.fields?.CssClass?.value=="center-aligned"?"row justify-content-center":"row"}>
            <div class="col-12">
              <h1>{props?.fields?.Title?.value}</h1>
              <p class="pt-2 pb-4">
                {props?.fields?.ShortDescription?.value}
              </p>
            </div>
          </div>
        </div>
      
     
      {(props?.fields?.Image?.value?.src!=undefined && props?.fields?.Image?.value?.src!="") &&(
        <div className="bgColor pb-2 pb-sm-4">
        <div className="bgCurve-top textBannerCurve">
          <div className="bgDiv d-md-none"></div>
          <img 
            src={
              "/-/media/adac_corporate/data/media/img/Themes/" +
              themeValue +
              "/banners/top-banner-curve.ashx"
            }
          />
        </div>
        {/* {console.log("styles:", styles, styles.bannerImgContainer,[styles.bannerImgContainer])} */}
        <div className="bannerImgWrapper container pt-2 pt-sm-0 position-relative">
        <div className="row">
            <div className="col-12">
            <img
              src={props?.fields?.Image?.value?.src}
              className="bannerImg"
              alt={props?.fields?.Image?.value?.alt}
            />
            </div>
            </div>
        </div>
      </div> )
      }
      </div>
      </>
);
}
export default withSitecoreContext() (TextBanner);
