import React from "react";
import { withSitecoreContext,RichText } from "@sitecore-jss/sitecore-jss-react";
import  "./RichText.css";
import "./ContentBlock.css";


const ContentBlock = (props) => {
  
  const { sitecoreContext } = props;
  let themeValue =
    sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value;
  if (themeValue == null || themeValue == "") {
    themeValue = sitecoreContext?.SiteTheme?.themeName;
    if (themeValue == null) {
      themeValue = "";
    }
  }
  return (
    
     props?.fields?.IsBackground?.value ? <>
      <div className="bgColorPrimary">
      <div className="container slim-body">
        <div className="row justify-content-center">
          <div className="col-12">
            <h1 className="pb-4">{props?.fields?.Title?.value}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="bgColor pb-2 pb-sm-4">
        <div className="bgCurve-top">
          <div className="CB_curvePad bgDiv d-md-none"></div>
          <img src={"/-/media/adac_corporate/data/media/img/Themes/"+themeValue+"/banners/top-banner-curve.ashx"} />
        </div>

        <div className="container pt-2 pt-sm-0 slim-body">
          <div className="bodyBox">
            <div className="row">
            <div className="col-12"><RichText field={props?.fields?.Content}/></div>
            </div>
          </div>
        </div>
      </div></>
      :<>
    <div className="container pt-2 position-relative slim-body pb-4">
    <div className="row">
      <div className="col-12">
        <h3>{props?.fields?.Title?.value}</h3>
        <RichText field={props?.fields?.Content} />
        
      </div>
    </div>
  </div>
  </>
  );
};

export default withSitecoreContext()(ContentBlock);
