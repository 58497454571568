import React from 'react';
import   './OurCompanies.css' ;
import   './CompanyCards.css';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const OurCompanies = (props) => {
  const { sitecoreContext } = props;
  const themeValue=sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value !="" && sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value !=null 
  ?sitecoreContext?.route?.fields?.Theme?.fields?.ThemeName?.value:sitecoreContext?.SiteTheme?.themeName!="" && sitecoreContext?.SiteTheme?.themeName!=null?
  sitecoreContext?.SiteTheme?.themeName:"";
  
  return (
    <section className="ourCompanies">
      <div className="innerContainer">
        <div className="textContainer">
          
          <h3>{props?.fields?.data?.datasource?.title?.value}</h3>
          <p className="medium-reg">
          {props?.fields?.data?.datasource?.subTitle?.value}
          </p>
          
        </div>
        <div className="imgWrapper">
        {props.fields?.data?.datasource?.CardItems.map((card, index) => (
          card?.isActive?.boolValue ? <>
           
          <div className="companyCard">
          <div className="cardImg">
            <img src={card?.image?.src} />
          </div>
          <div className="cardBody">
            <h5 className="hasMinhight">{card?.title?.value}</h5>
            <p className="large-reg mb-2">
            {card?.description?.value}
            </p>
           {card?.link?.url !=""&&card?.link?.url !=null &&(
            <a href={card?.link?.url == ('http://' || 'https://' || '') ? "#" : card?.link?.url}>
              <p className="btn-default">{card?.linkDescription?.value}</p>
              <img src={"/-/media/adac_corporate/data/media/img/Themes/"+themeValue+"/icons/iconright.ashx"} />
            </a>
)}
          </div>
        </div>
        </>:<></>
          ))}
        </div>
      </div>
    </section>
  );
  }

export default withSitecoreContext() (OurCompanies);
