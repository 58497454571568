import React from 'react';
import  './WhoWeAre.css';
import styles from '../../../ButtonGroup/ButtonGroup.module.css';
import PrimaryBtn  from '../../../ButtonGroup/PrimaryBtn';
import SecondaryBtn from '../../../ButtonGroup/SecondaryBtn';
const WhoWeAre = (props) => {
  let cssClass=props?.fields?.CssVariation?.value

  return(
    
  <section className={`whoWeAre ${cssClass}`}>
      <div className="innerContainer">
        <div className={`imgContainer ${cssClass}`}>
          <img
            className="img1"
            src={props?.fields?.ImageFirst?.value?.src}
            alt={props?.fields?.ImageFirst?.value?.alt}
          />
          <img
            className="img2"
            src={props?.fields?.ImageSecond?.value?.src}
            alt={props?.fields?.ImageSecond?.value?.alt}
          />
        </div>
        <div className={`textContainer ${cssClass}`}>
          <h3 className="heading">{props?.fields?.Title?.value}</h3>
          <p className="description x-large">
          {props?.fields?.Description?.value}
          </p>
          <div className="whoWeAreBtnGroupContainer">
          <div className={[styles.btnGroup +" btnGroupcontainer"]}>
          <SecondaryBtn 
             ctaLink={props?.fields?.CTASecond?.value?.href}
             ctaText={props?.fields?.CTASecondText?.value}
             ctaTarget={props?.fields?.CTASecond?.value?.target}
          />
          <PrimaryBtn 
           ctaLink={props?.fields?.CTAFirst?.value?.href}
           ctaText={props?.fields?.CTAFirstText?.value}
           ctaTarget={props?.fields?.CTAFirst?.value?.target}
           />
           </div>
           </div>
        </div>
      </div>
    </section>

  
  );
}

export default WhoWeAre;
