import React from "react";
import './LeaderCard.css';
import RedirectIcon from '../../assets/images/iconRight.svg'

const LeaderCard = ({data})=>{
    return <div className="universal-card">
        <div className="universal-card-ImageWpr">
        <img src={data?.image?.src} alt={data?.title?.value}/>
        </div>
        <div className="body">
            <div className="content">
            {/* {data.label && <p className="label">{data.label}</p>} */}
            <h5 className="title">{data?.title?.value}</h5>
            <p className="body-md-reg">{data?.description?.value}</p>
            </div>
            {/* {
                data.cta && <a className="cta">{data.cta}<img src={RedirectIcon} /></a>
            } */}

            
        </div>
    </div>
}

export default LeaderCard